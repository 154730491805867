import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import smoothscroll from 'smoothscroll-polyfill';

import { useGAEventTracker } from '../helpers/UseGAEventTracker';
import Bgimg from '../pics/bg/bg-lg-right-6.svg';
import download_icon from '../pics/icons/btn-download.svg';
import book_cover from '../pics/books/The_Rising_of_NFT2_0_by_Envelop_cover.webp';
import inner_page from '../pics/books/inner-page.jpg';
import Loader from '../pics/icons/i-loading.svg';
import config from '../config.json';

smoothscroll.polyfill();

function withHooks(WrappedComponent) {
  return function(props) {
    const gaEventTracker = useGAEventTracker('Book');
    return (
      <WrappedComponent gaEventTracker={gaEventTracker} {...props} />
    );
  };
}

class LearnPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      json: [],
      items: [],
      isLoaded: false
    };
  }

  async componentDidMount() {
    const url = config.mainURL + config.learnURL + config.formatURL;
    this.fetchItems(url);
  }

  fetchItems(url,network='',page=0,search=0) {
    try {
      fetch(url)
        .then((res) => res.json())
        .then((json) => {
          if ('error' in json) {
            throw new Error( json["error"] );
          }
          else {
            this.setState({
              json: json,
              items: json,
              isLoaded: true
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    } catch(e) {
      console.log(e);
    }
  }

  render() {
    // const gaEventTracker = useGAEventTracker('Book');
    const gaEventTracker = this.props.gaEventTracker;
    const { isLoaded, items } = this.state;

  return (
    <>
      <Helmet>
        <title>Learn about NFT 2.0</title>
        <meta name="description" content="Check out our books with NFT market insights." />
      </Helmet>
      <main className="s-main">
        <section className="sec-back"> 
          <div className="container">
          <Link to="/" className="btn btn-gray btn-sm"> 
            <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.6668 9.16659H6.52516L11.1835 4.50825L10.0002 3.33325L3.3335 9.99992L10.0002 16.6666L11.1752 15.4916L6.52516 10.8333H16.6668V9.16659Z" fill="white"></path>
            </svg><span>Back    </span></Link></div>
        </section>
        <section className="sec-intro">
            <div className="bg-gradient"></div><img className="sec-bg bg-right d-none d-xl-block" src={ Bgimg } alt="" />
            <div className="container">
              <div className="row align-items-center"> 
                <div className="col-lg-7 pb-4"> 
                  <h1>Learn About <span className="text-grad">NFT 2.0</span></h1>
                  <p className="pr-lg-4">On this page, you can find unique books, research papers and articles in the <a href="https://hackernoon.com/nft-20-the-era-of-programmable-assets" target="_blank" rel="noreferrer nofollow">NFT 2.0</a> sphere, also known as Utility NFTs, Smart NFTs, and programmable NFTs. To be more specific, NFT 2.0 includes financial, dynamic, wrapped (or charged), and many other types of non-fungible tokens. NFT 2.0 tokens themselves are part of programmable assets.</p>
                </div>
                <div className="col-lg-4 offset-lg-1"> 
                  <div className="promo-book"> <a className="promo-book__link" href="/files/The_Rising_of_NFT2_0_by_Envelop.pdf" onClick={()=>gaEventTracker('bookpdf','Download PDF book')} target="_blank">
                    <div className="book"><img className="page page-left" src={ inner_page } alt="" /><img className="page page-right" src={ inner_page } alt="" /><img className="cover" src={ book_cover } alt="" /><img className="download" src={ download_icon } alt="" /></div>
                    <div className="caption">Check out our new book!</div></a>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <section className="sec-learn">
            <div className="container">
              <div className="row">
                {
                  !isLoaded ? (
                    <div className="ml-3">
                      <img src={Loader} alt="" /> 
                      <h4>Loading..</h4>
                    </div>
                  ) : (
                    (items.length) ? items.map((item,key) => (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-card" key={item.id}>
                        <div className="sec-learn__item">
                          <div className="image"><a href={ item.url } title={ item.title } onClick={()=>gaEventTracker('learnitem'+item.id,'Click Learn item ' + item.id)}><img src={ config.mainURL + item.image } alt={ item.title } /></a></div>
                          <div className="title"><a href={ item.url } title={ item.title } onClick={()=>gaEventTracker('learnitem'+item.id,'Click Learn item ' + item.id)}>{ item.title }</a></div>
                        </div>
                      </div>
                    )) : null
                  )
                }
              </div>
            </div>
          </section>
      </main>
    </>
  );
}
}

export default withHooks(LearnPage);
